<template>
  <div id="update">
    <div class="update-card">
      <div class="card-title">
        <h1>ACTUALIZAR DATOS</h1>
      </div>

      <div class="content">
        <form @submit.prevent="validate">
          <b-field
            :message="[
              {
                'El nombre es obligatorio.':
                  !$v.names.required && $v.names.$error,
              },
              {
                'La cantidad máxima de caracteres son 50.': !$v.names.maxLength,
              },
              {
                'El nombre no debe tener caracteres especiales ni números.': !$v
                  .names.nameRegex,
              },
            ]"
            type="is-danger"
          >
            <input
              id="names"
              type="text"
              name="names"
              title="names"
              placeholder="Nombre completo"
              autofocus
              v-model="names"
              @input="$v.names.$touch()"
              @blur="$v.names.$touch()"
              :class="$v.names.$error ? 'error-input' : ''"
              autocomplete="on"
            />
          </b-field>
          <b-field
            class="mt-5"
            :message="[
              {
                'El correo electrónico es obligatorio.':
                  !$v.email.required && $v.email.$error,
              },
              {
                'Formato de correo electrónico inválido.': !$v.email.email,
              },
              {
                'El correo electrónico no debe tener caracteres especiales.': !$v
                  .email.mailRegex,
              },
              {
                'La cantidad máxima de caracteres son 50.': !$v.email.maxLength,
              },
            ]"
            type="is-danger"
          >
            <input
              id="email"
              type="email"
              name="email"
              title="email"
              placeholder="Correo electrónico"
              autofocus
              v-model="email"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              :class="$v.email.$error ? 'error-input' : ''"
              autocomplete="on"
            />
          </b-field>

          <button type="submit" class="btn btn-primary mt-5">Actualizar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, maxLength, helpers, email } from "vuelidate/lib/validators";
const nameRegex = helpers.regex("alpha", /^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1., ]*$/);
const mailRegex = helpers.regex("alpha", /^[0-9a-zA-Z@._\-ñÑ]*$/);
export default {
  data() {
    return {
      names: "",
      email: "",
    };
  },
  created() {
    let userData = JSON.parse(localStorage.user);
    this.names = userData.name;
    this.email = userData.email;
  },
  methods: {
    async updateData() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          name: this.names,
          email: this.email,
        };
        let userId = JSON.parse(localStorage.user).id;

        await this.$userService.updateData(userId, data);
        this.changeLoaderStatus(false);
        let userData = JSON.parse(localStorage.user);
        userData.name = this.names;
        userData.email = this.email;

        localStorage.setItem("user", JSON.stringify(userData));
        this.$global.$emit("userUpdateData");
        this.$router
          .push({ name: "Home" })
          .then(() => {
            this.$buefy.toast.open({
              duration: 5000,
              message: `Tus datos han sido actualizados correctamente.`,
              type: "is-info",
            });
          })
          .catch(() => {
            this.showToast(
              "Ha ocurrido un error inesperado.",
              "is-danger",
              3000
            );
          });
      } catch (error) {
        this.changeLoaderStatus(false);
        if (error.response.data.statusCode == 409) {
          this.showToast(
            `Ya existe un usuario con la dirección de correo ${this.email} en el sistema.`,
            "is-danger",
            3000
          );
        } else {
          this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
        }
      }
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateData();
      }
    },
  },
  validations: {
    names: {
      required,
      nameRegex,
      maxLength: maxLength(50),
    },
    email: {
      required,
      email,
      mailRegex,
      maxLength: maxLength(50),
    },
  },
};
</script>

<style scoped>
@charset "UTF-8";
#update {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background: transparent;
}
#update .update-card {
  background: var(--white-color);
  width: 24rem;
  box-shadow: 0 0 34px 0 rgba(0, 0, 0, 0.34);
  border-radius: 10px;
}
#update .update-card .card-title {
  background-color: var(--dark-blue-color);
  padding: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#update .update-card .card-title h1 {
  color: var(--white-color);
  text-align: center;
  font-size: 1.2rem;
}
#update .update-card .content {
  padding: 3rem 2.5rem 3rem;
}
#update .update-card input,
#update .update-card input {
  display: block;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
  border: none;
  border-bottom: 1px solid #dbdbdb;
  transition: all 0.5s;
}
#update .update-card input:hover,
#update .update-card input:hover {
  border-color: #7a7a7a;
}
#update .update-card input:active,
#update .update-card input:focus {
  border-color: var(--dark-blue-color);
}
#update .update-card .checkbox {
  color: #b5b5b5;
  font-size: 0.8rem;
}
#update .update-card .checkbox span {
  margin-left: 0.5rem;
}
#update .update-card a {
  font-size: 0.8rem;
}
#update .update-card .options {
  color: #b5b5b5;
  margin-bottom: 1.5rem;
}
#update .update-card button {
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--dark-blue-color);
  border-radius: 4rem;
  display: block;
  width: 100%;
  background: transparent;
  border: 2px solid var(--dark-blue-color);
  padding: 0.9rem 0 1.1rem;
  transition: color 0.5s, border-color 0.5s;
}
#update .update-card button:hover,
#update .update-card button:focus {
  border-color: var(--dark-blue-color);
  color: var(--white-color);
  background: var(--dark-blue-color);
}
#update .update-card button:active {
  transform: translateY(1px);
}

label {
  cursor: pointer;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #fafafa;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 7px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}

.regular-checkbox:checked + label {
  background-color: #e9ecee;
}

.regular-checkbox:checked + label:after {
  content: "✔";
  font-size: 11px;
  position: absolute;
  top: 0;
  left: 3px;
  color: #b5b5b5;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.update-option {
  color: #7a7a7a;
}

.level-right {
  color: var(--dark-blue-color);
}

.update-option span {
  color: var(--dark-blue-color);
}

.password-eye {
  color: var(--dark-blue-color);
  cursor: pointer;
}

#update .update-card .error-input:active,
#update .update-card .error-input:focus,
#update .update-card .error-input:active,
#update .update-card .error-input:focus {
  border-color: var(--dark-red-color);
}

@media screen and (max-width: 768px) {
  #update .update-card .options {
    display: grid;
  }
}
/* Made by: Paul Barker */
/* https://codepen.io/paulhbarker/pen/mwOREz */
</style>
