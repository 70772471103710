var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"update"}},[_c('div',{staticClass:"update-card"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('b-field',{attrs:{"message":[
            {
              'El nombre es obligatorio.':
                !_vm.$v.names.required && _vm.$v.names.$error,
            },
            {
              'La cantidad máxima de caracteres son 50.': !_vm.$v.names.maxLength,
            },
            {
              'El nombre no debe tener caracteres especiales ni números.': !_vm.$v
                .names.nameRegex,
            } ],"type":"is-danger"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.names),expression:"names"}],class:_vm.$v.names.$error ? 'error-input' : '',attrs:{"id":"names","type":"text","name":"names","title":"names","placeholder":"Nombre completo","autofocus":"","autocomplete":"on"},domProps:{"value":(_vm.names)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.names=$event.target.value},function($event){return _vm.$v.names.$touch()}],"blur":function($event){return _vm.$v.names.$touch()}}})]),_c('b-field',{staticClass:"mt-5",attrs:{"message":[
            {
              'El correo electrónico es obligatorio.':
                !_vm.$v.email.required && _vm.$v.email.$error,
            },
            {
              'Formato de correo electrónico inválido.': !_vm.$v.email.email,
            },
            {
              'El correo electrónico no debe tener caracteres especiales.': !_vm.$v
                .email.mailRegex,
            },
            {
              'La cantidad máxima de caracteres son 50.': !_vm.$v.email.maxLength,
            } ],"type":"is-danger"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:_vm.$v.email.$error ? 'error-input' : '',attrs:{"id":"email","type":"email","name":"email","title":"email","placeholder":"Correo electrónico","autofocus":"","autocomplete":"on"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.email=$event.target.value},function($event){return _vm.$v.email.$touch()}],"blur":function($event){return _vm.$v.email.$touch()}}})]),_c('button',{staticClass:"btn btn-primary mt-5",attrs:{"type":"submit"}},[_vm._v("Actualizar")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title"},[_c('h1',[_vm._v("ACTUALIZAR DATOS")])])}]

export { render, staticRenderFns }